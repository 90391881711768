import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  description: string
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0 20px;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 10px 0 20px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    padding-left: 30px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: 85%;
  }
`
const Hr = styled.div`
  width: 80px;
  height: 8px;
  margin: 20px auto 0 auto;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 3px;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    margin: 0;
  }
`
const About: React.FC<Props> = ({ description }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <InnerWrapper>
          <Text
            size={lg ? 16 : 15}
            weight={500}
            themecolor="black"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Hr />
        </InnerWrapper>
      </Container>
    </Section>
  )
}

export default About
