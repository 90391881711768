import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/DlaDeveloperow/About'
import Tiles from 'components/layout/Sections/Pages/DlaDeveloperow/Tiles'
import InfoWithButton from 'components/layout/InfoWithButton'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Dla developerów',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Dla developerów',
    },
  ],
}

const heroHeading = 'Dla developerów'

const aboutDescription =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab necessitatibus cum dignissimos laudantium quod nihil, dolores quis placeat corporis excepturi, ipsum nesciunt. Dolore quae nisi magni numquam incidunt facilis vel excepturi est eius illum molestiae, maiores pariatur earum non laudantium esse! Fugiat quaerat laborum ipsum ratione temporibus, saepe in debitis reiciendis tempora veritatis. Praesentium, inventore.'

const infoText =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam esse ab dolorum voluptas. Eos cumque amet ad a quam nisi minima, nihil iusto. Blanditiis ipsum aliquid at esse quo ex quia, aspernatur omnis neque hic?'

const DlaDeveloperowPage: React.FC<PageProps> = ({ data }) => {
  const PAGE_SEO = data?.wpPage?.seo

  const GALLERY = React.useMemo(
    () => [
      {
        img: data.placeholderImg.childImageSharp.gatsbyImageData,
        productImg: data.placeholderImg.childImageSharp.gatsbyImageData,
        descriptionTitle: 'Lorem ipsum',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum earum voluptates dignissimos voluptatem non. Omnis sequi delectus exercitationem voluptatibus placeat nisi culpa sed rem itaque ipsam ullam aspernatur, pariatur temporibus.',
      },
      {
        img: data.placeholderImg.childImageSharp.gatsbyImageData,
        productImg: data.placeholderImg.childImageSharp.gatsbyImageData,
        descriptionTitle: 'Lorem ipsum',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum earum voluptates dignissimos voluptatem non. Omnis sequi delectus exercitationem voluptatibus placeat nisi culpa sed rem itaque ipsam ullam aspernatur, pariatur temporibus.',
      },
      {
        img: data.placeholderImg.childImageSharp.gatsbyImageData,
        productImg: data.placeholderImg.childImageSharp.gatsbyImageData,
        descriptionTitle: 'Lorem ipsum',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum earum voluptates dignissimos voluptatem non. Omnis sequi delectus exercitationem voluptatibus placeat nisi culpa sed rem itaque ipsam ullam aspernatur, pariatur temporibus.',
      },
      {
        img: data.placeholderImg.childImageSharp.gatsbyImageData,
        productImg: data.placeholderImg.childImageSharp.gatsbyImageData,
        descriptionTitle: 'Lorem ipsum',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum earum voluptates dignissimos voluptatem non. Omnis sequi delectus exercitationem voluptatibus placeat nisi culpa sed rem itaque ipsam ullam aspernatur, pariatur temporibus.',
      },
    ],
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={data.heroImgMobile.childImageSharp.gatsbyImageData}
        imgDesktop={data.heroImg1.childImageSharp.gatsbyImageData}
        headingMobile={heroHeading}
        headingDesktop={heroHeading}
        breadcrumbs={breadcrumbs}
      />
      <About description={aboutDescription} />
      <Tiles gallery={GALLERY} />
      <InfoWithButton text={infoText} />
    </Layout>
  )
}

export default DlaDeveloperowPage

export const query = graphql`
  query DlaDeveloperowPage {
    wpPage(slug: { regex: "/dla-deweloperow/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
    }
    heroImgMobile: file(name: { eq: "hero-img-mobile-1" }) {
      childImageSharp {
        gatsbyImageData(
          width: 992
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    heroImg1: file(name: { eq: "hero-img-5" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    heroImg2: file(name: { eq: "hero-img-6" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    placeholderImg: file(name: { eq: "background-primary" }) {
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
  }
`
